import React, {useState, useEffect} from 'react'
import join from 'lodash/join'
import moment from 'moment'
import { PDFDocument } from 'pdf-lib'


const Form = () => {
  const [formData, setFormData] = useState(null)
  const [loading, setLoading] = useState(false)

  const changeFormData = (type, value) => {
    const newFormData = {...formData, [type]: value}
    setFormData(newFormData)
    window.localStorage.setItem("formData", JSON.stringify(newFormData))
  }

  const clearForm = () => {
    setFormData(null)
    window.localStorage.removeItem("formData")
  }

  const processForm = async (e) => {
    try {
      setLoading(true)
      e.preventDefault()
      const DOMAIN = window.location.origin
      const regularUrl = `${DOMAIN}/templates/program-template.pdf`
      const fastUrl = `${DOMAIN}/templates/fast-program-template.pdf`

      const url = formData?.fast ? fastUrl : regularUrl
      const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    
      const pdfDoc = await PDFDocument.load(existingPdfBytes)

      const form = pdfDoc.getForm()

      const fields = form.getFields()

      const presiding = form.getTextField("presiding")
      presiding.setText(formData?.presiding)

      const dateFormat = moment(formData?.date).format('MMMM Do')
      form.getTextField("date").setText(dateFormat !== "Invalid date" ? dateFormat : "")
      form.getTextField("openingHymn").setText(formData?.openingHymn)
      form.getTextField("openingPrayer").setText(formData?.openingPrayer)
      form.getTextField("sacramentHymn").setText(formData?.sacramentHymn)
      form.getTextField("stakeBusiness").setText(formData?.stakeBusiness)
      form.getTextField("closingPrayer").setText(formData?.closingPrayer)
      form.getTextField("closingHymn").setText(formData?.closingHymn)
      form.getTextField("musicThanks").setText(formData?.musicThanks)
      
      const chunkAnnouncmentArrays = setTextTypes(formData?.annoucments, false)
      let announcmentArrayIndex = 0

      const chunkWardBusinessArrays = setTextTypes(formData?.wardBusiness, false)
      let wardBusinessArrayIndex = 0

      let speakersArrayIndex = 0
      let speakersArray = setTextTypes(formData?.speakers, true)

      fields.forEach(field => {
        const name = field.getName()
        if(name.includes("announcement")) {
          form.getTextField(`announcements${announcmentArrayIndex + 1}`).setText(join(chunkAnnouncmentArrays[announcmentArrayIndex], " "))
          // console.log(form.getTextField(`announcements${announcmentArrayIndex + 1}`).getFieldFull())
          announcmentArrayIndex ++
        }

        if(formData?.wardBusiness && name.includes('wb')) {
          form.getTextField(`wb${wardBusinessArrayIndex + 1}`).setText(join(chunkWardBusinessArrays[wardBusinessArrayIndex], " "))
          wardBusinessArrayIndex ++ 
        }

        if(formData?.speakers && name.includes('speakers')) {
          form.getTextField(`speakers${speakersArrayIndex + 1}`).setText(speakersArray[speakersArrayIndex])
          speakersArrayIndex ++
        }
      })
    

      pdfDoc.setTitle(`${dateFormat} 2024 Program`)
      const pdfBytes = await pdfDoc.save()
      const blob = new Blob([pdfBytes]);
      const fileUrl = window.URL.createObjectURL(blob);

      let alink = document.createElement("a");
      alink.href = fileUrl;
      alink.download = `Quail Ridge${formData?.fast ? " Fast" : ""} ${dateFormat !== "Invalid date" ? dateFormat : "Blank"} 2024 Program.pdf`;
      alink.click();
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const setTextTypes = (data, splitOnCommas) => {
    let newArray = []
    if(data && data.includes(",") && splitOnCommas) {
      newArray = data.split(",")
    } else if( data && data.match(/\n/g)) {
      newArray = data.split(/\r?\n/)
    } else {
      newArray.push(data)
    }
    return newArray
  }

  // const setTextSize = () => {
  //   if ( (event.fieldFull = true) && (event.value.toString().length <= 7) ) event.target.textSize = 19;

  //   else if ( (event.fieldFull = true) && (event.value.toString().length > 7) ) event.target.textSize = 0;
  // }

  useEffect(() => {
      const data = JSON.parse(window.localStorage.getItem("formData"))
      setFormData(data)
  }, [])

  const chunkArrays = (text = "", numberOfLines) => {
    const textArray = text.includes(" ") ? text?.split(" ") : [text]
    const lengthOfLine = textArray.length > 10 ? textArray.length / numberOfLines : textArray.length
    const chunkArrays = []

    for(let i = 0; i <= numberOfLines; i ++)
    {
      if(textArray.length > 0 && textArray.length >= lengthOfLine) {
        console.log(textArray, "here")
        chunkArrays.push(textArray.splice(0, lengthOfLine))
      }
      else if (textArray.length < lengthOfLine) {
        console.log(textArray, "here again")
        chunkArrays.push(textArray)
      }
      else {
        console.log(textArray, "here again 3")
        continue
      }
    }
    return chunkArrays
  }
  

  return (
  <>
    <form>
      <h1>Sacrament Program</h1>
      <h2>
        Fast Sunday?
      </h2>
      <div className='fastForm'>
        <label htmlFor="yes" className="radio">
          <span>Yes</span>
          <input type="radio" value="Yes" name="fast" id="yes" checked={formData?.fast || false} onChange={(e) => changeFormData('fast', true)} />
        </label>
        <label htmlFor="no" className="radio">
        <span>No</span>
          <input type="radio" value="No" name="fast" id="no" checked={!formData?.fast || true} onChange={(e) => changeFormData('fast', false)} />
        </label>
      </div>

      <label htmlFor="date">
      <span>Date:</span>
        <input type="date" name="date" id="date" onChange={(e) => changeFormData('date', e.target.value)} value={formData?.date  || ""} />
      </label>

      <label htmlFor="presiding">
        <span>Presiding:</span>
        <input type="text" name="presiding" id="presiding" onChange={(e) => changeFormData('presiding', e.target.value)} value={formData?.presiding || ""} />
      </label>

      <label htmlFor="annoucments">
        <span>Annoucments:</span>
        <textarea name="annoucments" id="annoucments" cols="30" rows="10" onChange={(e) => changeFormData('annoucments', e.target.value)} value={formData?.annoucments || ""} ></textarea>
      </label>

      <label htmlFor="openingHymn">
        <span>Opening Hymn</span>
        <input type="text" name="openingHymn" id="openingHymn" onChange={(e) => changeFormData('openingHymn', e.target.value)} value={formData?.openingHymn || ""} />
      </label>

      <label htmlFor="openingPrayer">
        <span>Opening Prayer</span>
        <input type="text" name="openingPrayer" id="openingPrayer" onChange={(e) => changeFormData('openingPrayer', e.target.value)} value={formData?.openingPrayer || ""} />
      </label>

      <label htmlFor="musicThanks">
      <span>Thanks for the music</span>
        <input type="text" name="musicThanks" id="musicThanks" onChange={(e) => changeFormData('musicThanks', e.target.value)} value={formData?.musicThanks || ""} />
      </label>

      <label htmlFor="wardBusiness">
        <span>Ward Business</span>
        <textarea name="wardBusiness" id="wardBusiness" cols="30" rows="10" onChange={(e) => changeFormData('wardBusiness', e.target.value)} value={formData?.wardBusiness || ""} ></textarea>
      </label>

      <label htmlFor="stakeBusiness">
        <span>Stake Business</span>
        <textarea name="stakeBusiness" id="stakeBusiness" cols="30" rows="10" onChange={(e) => changeFormData('stakeBusiness', e.target.value)} value={formData?.stakeBusiness || ""} ></textarea>
      </label>

      <label htmlFor="sacramentHymn">
        <span>Sacrament Hymn</span>
        <input type="text" name="sacramentHymn" id="sacramentHymn" onChange={(e) => changeFormData('sacramentHymn', e.target.value)} value={formData?.sacramentHymn || ""} />
      </label>

      {!formData?.fast && 
        <label htmlFor="speakers">
        <span>Speakers</span>
          <textarea name="speakers" id="speakers" cols="30" rows="10" onChange={(e) => changeFormData('speakers', e.target.value)} value={formData?.speakers || ""} ></textarea>
        </label> 
      }

      <label htmlFor="closingHymn">
        <span>Closing Hymn</span>
        <input type="text" name="closingHymn" id="closingHymn"  onChange={(e) => changeFormData('closingHymn', e.target.value)} value={formData?.closingHymn || ""} />
      </label>

      <label htmlFor="closingPrayer">
        <span>Closing Prayer</span>
        <input type="text" name="closingPrayer" id="closingPrayer" onChange={(e) => changeFormData('closingPrayer', e.target.value)} value={formData?.closingPrayer || ""} />
      </label>

  </form>
  <div className='footer'>
      <button className="clear" onClick={() => clearForm()}>Clear Form</button>
      <button className="submit" onClick={(e) => processForm(e)} disabled={loading}>Submit</button>
    </div>
</>
  )
}

export default Form